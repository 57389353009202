$(document).ready(function (){
	/* Switch date field order for non-us locations */
	arrangeDateFields('.login_dateStamp','.login_month','.login_date');

	/* Initialize validator for login form */
	$('#login').validate({
		submitHandler: function(form) { 
			$('#login-btn').addClass('hidden');
			$('#login-loading').removeClass('hidden');
			form.submit();
		},
		messages:{
			firstName: "First Name is required.",
			lastName: "Last Name is required.",
			birthday: "Birth Day is required.",
			birthmonth: "Birth Month is required.",
			birthyear: "Birth Year is required.",
			bookingId: "Booking Number is required."
		}
	});

	$('#signin').validate({
		messages:{
			username: {
				required: "Email Address is required."
			},
			password: "Password is required."
		}
	});

	/* Ajax request for Signin */
	$('#signin').makeRequest({
		container : $('#cp-signin'),
		dataType : 'json',
		event : 'submit',
		reqFail: function(req,error,$button,$loading,$msg){
			$(this).makeRequest.toggleBtn($('#signin-btn'),true,'','');
			$(this).makeRequest.toggleLoading($('#signin-loading'),false);
			$(this).makeRequest.toggleMsg($('#signin-msg'),true,'error','','An error has occurred. ' + error);
			$('#signin-msg').attr('tabindex',0);
			$('#signin-msg').on('focus');
		},
		reqSuccess: function (results,$form,$button,$loading,$msg) {
			var url = DOMPurify.sanitize('index.page?page='+ $('#page').val()+'&cpfy='+ $('#cpfy').val());
			// DTM dataLayer implenentation WAI-32
			window.dtm_dataLayer.push({
					'event': 'signinSuccess',
				});
			dtm_digitalData['loginLocation'] = 'Manage Booking';
			if (results.data.passwordUpgraded === 'No') {
				var resetPasswordUrl = $bookURL + '/captaincircle/showResetPassword.page';
				$.magnificPopup.open({
						items: {
							src: resetPasswordUrl
						},
						type: 'iframe',
						removalDelay: 300,
						alignTop: true,
						modal: true,
						showCloseBtn: true,
						mainClass: 'border-box-sizing mfp-entry-point',
						overflowY: 'scroll',
						callbacks: {
							open: function(item) {
								$('.mfp-close').hide(); 
								var item = this.currItem,
									container = this.container;
								lightboxOpenFocusFix(item, container);
							}
						}
				});
			}
			else {
				location.href = url;
			}
			dtm_digitalData['loginLocation'] = 'Manage Booking';	
		},
		reqError: function (results,$form,$button,$loading,$msg) {
			var href = 'index.page?page='+ $('#page').val();
			switch(results.status){
				case "HARD_BOUNCE_EMAIL":
					location.href = DOMPurify.sanitize(href);
					break;
				case "ERROR":
				default:	
					$(this).makeRequest.toggleBtn($('#signin-btn'),true,'','');
					$(this).makeRequest.toggleLoading($('#signin-loading'),false);
					$(this).makeRequest.toggleMsg($('#signin-msg'),true,'error','',results.message);
					$('#signin-msg').attr('tabindex',0);
					$('#signin-msg').on('focus');
			}
		},
		validate: false
	});

	//Accessibility changes
	if( $('.errorMessage').length > 0 ){
		$('#login-msg').attr('tabindex',0);
		$('#login-msg').on('focus');
	}

	/**
	 * New Login page booking guest toggle functionality
	 */
	 $('.login-toggle-btn').on('click',function (e) {
		var $btn = $(this),
			$bgGuest = $('#login-wrapper');
		$bgGuest.slideToggle();
		if ($btn.hasClass('collapsed')) {
			$btn.addClass('expanded').removeClass('collapsed').attr('aria-expanded', 'true');
		} else {
			$btn.addClass('collapsed').removeClass('expanded').attr('aria-expanded', 'false');
		}
	});
});
